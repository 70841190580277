import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/User/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DangerBox from 'components/Web_User_Interface/1440p_Series/System/User/DangerBox';
import UserTable from 'components/Web_User_Interface/1440p_Series/System/User/userTable';
import UserPermissionTable from 'components/Web_User_Interface/1440p_Series/System/User/userPermissionTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // User Management",
  "path": "/Web_User_Interface/1440p_Series/System/User/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them. You can use the RTSP Stream if you want to allow users to access your camera without the hassle of a password.",
  "image": "./WebUI_1440p_SearchThumb_System_User.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_User.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menü // Benutzerverwaltung' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Die Weboberfläche Ihrer Kamera bietet Ihnen eine dreistufige Benutzerverwaltung für Benutzer mit unterschiedlichen Zugriffsrechten für Administratoren, Benutzer und Besucher. Sie können die Benutzernamen und zugehörige Kennwörter für alle 3 Stufen im Benutzer-Overlay festlegen. Gäste [guest] haben nur Zugriff auf den Videostream der Kamera, Benutzer [user] können die Kamera zusätzlich noch schwenken und nur der Administrator [administrator] hat Zugriff auf die Konfigurationsmenüs der Kamera.' image='/images/Search/WebUI_1440p_SearchThumb_System_User.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_User.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Benutzer/' locationFR='/fr/Web_User_Interface/1440p_Series/System/User/' crumbLabel="User Management" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be8009d939c80871df306dc4b89aa902/024d6/1440p_Settings_System_User.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC2klEQVQ4y22SzW8bRRyG80cgIWgUO1wcO147TR0bf629dryJd7327sx+eZdIRP0G2oJaIXGh1Byoekh7AAkOSJyRCEj8gQ+aMQmkzeHV/kY78+h9552NqmFQLu9Qv9XGe/QG+/MfGT94w+jea8w7Z/Run9F/S+ad1xzePyP+5jeGbsIH77/HhzduUCwW2ahWDco7JW42u3z6/e9kq7/IXpyTrf4kfn5O9PwPwm/fVb465/Tl3/SOEz4qFiiVypRKO2xUKhWUakaVuWMTLhwWrq0VzI5wbYtht4nZadBv38JsN7S6rX2mkyFja0yvZzEwxxwcfMzG7u4u5XKZWr1OICTLLCdOEoSQCBniuDMsa4Q5GNDpdOl0e1rtThfXmXFsT+n3h+8CDcMgCALSNCWOY4QQWo7jYJom3W6XXq93KbV2XRfPPcIaWpj9MQeNa4DL5RIppZ7XQJfBYKABnU7nihRQCpuhOdDA5pXItRq+75MkyX8Og0Afsm2byWTCyLKwLIvRaKS/nucxdydY5oBDy6LVbF11qJzlebYGBgGBjAijmCSOiaKIME6Jk1TPMgz1/ql9yHhoMhkNaR60/t+yQSBj0uyEJFFAHxFnpNknZMsloZSIOCdVpcWRTqNSnEiHo7HF2Dqk2Wyrd1hFuVQOfRFqQJ7naxcyvLwCtVZS88WVBEIQuRatxk329xsY1do6snZYq+mYyo06qA/8W8zl/NZaRa4aNTY3NykUChSLhXXki1LUxjxbskwTvVnFvHCn3mgUJyRJSqLcC6H/7+3tsbW1xfb2tta6lEqFer2G50u8MGcml/j6Ya9LiRXAdwlmx/gLl6kQHAvBPJTs1etXgcrhrirGqPPFicevX8/5+anH/dxnHoTahYKGD1fIx69IT5+xmrq8dDy+9BZUrwNWKmUN/Cyf8cuzOT995XE3WzD3hW47ECHywXdET16RnD7lhTPjh5nPk2uA/wB9ECn5tR9i4gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be8009d939c80871df306dc4b89aa902/e4706/1440p_Settings_System_User.avif 230w", "/en/static/be8009d939c80871df306dc4b89aa902/d1af7/1440p_Settings_System_User.avif 460w", "/en/static/be8009d939c80871df306dc4b89aa902/7f308/1440p_Settings_System_User.avif 920w", "/en/static/be8009d939c80871df306dc4b89aa902/7733b/1440p_Settings_System_User.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be8009d939c80871df306dc4b89aa902/a0b58/1440p_Settings_System_User.webp 230w", "/en/static/be8009d939c80871df306dc4b89aa902/bc10c/1440p_Settings_System_User.webp 460w", "/en/static/be8009d939c80871df306dc4b89aa902/966d8/1440p_Settings_System_User.webp 920w", "/en/static/be8009d939c80871df306dc4b89aa902/85db8/1440p_Settings_System_User.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be8009d939c80871df306dc4b89aa902/81c8e/1440p_Settings_System_User.png 230w", "/en/static/be8009d939c80871df306dc4b89aa902/08a84/1440p_Settings_System_User.png 460w", "/en/static/be8009d939c80871df306dc4b89aa902/c0255/1440p_Settings_System_User.png 920w", "/en/static/be8009d939c80871df306dc4b89aa902/024d6/1440p_Settings_System_User.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be8009d939c80871df306dc4b89aa902/c0255/1440p_Settings_System_User.png",
              "alt": "Web User Interface - 1440p Series - System User",
              "title": "Web User Interface - 1440p Series - System User",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The web user interface by default offers a 3-level user management with different access rights for administrators, operators and visitors. You can set user names and passwords for all three user levels in the Users mask. Guests will only have access to the video stream of the camera, Users in addition are able to use the pan & tilt function and only the Administrator will have access to all the camera's configuration menus.`}</p>
    <p>{`You are able to add more users beside those 3 default user by clicking on the green plus icon in the top right of the menu. Or edit two of the default logins - `}<strong parentName="p">{`the administrator account cannot be edited or deleted`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For the `}<em parentName="p">{`2-factor authentication`}</em>{` (`}<strong parentName="p">{`2fa`}</strong>{`) and `}<em parentName="p">{`password recovery`}</em>{` please check out our `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_2fa_Authentication"
        }}>{`FAQ on 2fa`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "access-restrictions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-restrictions",
        "aria-label": "access restrictions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access Restrictions`}</h2>
    <ul>
      <li parentName="ul">{`See also: `}<a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_User_Permissions/"
        }}>{`FAQ :: Is it possible to access the live video without having access to the web user interface?`}</a></li>
    </ul>
    <p>{`As seen in the screenshot above you can choose from a variety of user rights for those accounts:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserPermissionTable mdxType="UserPermissionTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      